/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.diagnostics .mat-dialog-container {
    background-color: rgb(241, 245, 249) !important;
    height: 100% !important;
}

.custom-dialog-container .mat-dialog-container {
    padding: 10px 24px 24px 24px;
}

::ng-deep {
    &::-webkit-scrollbar {
        width: 8px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        height: 30px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
        height: 30px;

        &:hover {
            background-color: #999;
        }
    }
}